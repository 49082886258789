import * as React from "react"
import { graphql } from "gatsby"
import { Routes } from "../data/routes"
import Seo from "../components/seo"

function Header(props) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-17d3a4a8538b7d528d954a5db2874da8
  return (
    <div className="relative bg-secondary">
      <div className="relative max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-center tracking-tight text-white">
          {props.title}
        </h1>
        <p className="mt-4 text-primary text-center text-xl">{props.date}</p>
      </div>
    </div>
  )
}

const AnnouncementTemplate = ({ data }) => {
  const announcement = data.markdownRemark

  return (
    <div className="bg-background min-h-screen">
      <Seo
        title={announcement.frontmatter.title}
        route={`${Routes.teacherAnnouncements}${announcement.frontmatter.slug}`}
      />
      <div className="flex-grow">
        <Header
          title={announcement.frontmatter.title}
          date={announcement.frontmatter.date}
        />
        <div className="max-w-4xl mx-auto relative px-4 sm:px-6 lg:px-8 py-8">
          <div className="my-6 prose prose-seconary prose-lg text-gray-500 mx-auto">
            <article
              className="announcement"
              itemScope
              itemType="http://schema.org/Article"
            >
              <section
                dangerouslySetInnerHTML={{ __html: announcement.html }}
                itemProp="articleBody"
              />
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnouncementTemplate

export const pageQuery = graphql`
  query AnnouncementBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        slug
      }
    }
  }
`
